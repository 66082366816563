import DynamicComponent from "../components/storyblok/dynamicComponent"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import useStoryblok from "../lib/storyblok"

const Page = ({ pageContext, location }) => {
	let story = pageContext.story
	story = useStoryblok(story, location)

	const components = story.content.body?.map(blok => {
		return (
			<DynamicComponent
				blok={blok}
				key={blok._uid}
				isRTDPage={story.content?.isRTDPage}
			/>
		)
	})

	return (
		<Layout isRTDPage={story.content?.isRTDPage}>
			<Seo title={story.name} />
			{components || story.content}
		</Layout>
	)
}

export default Page
